import clamp from 'lodash/clamp'
import PropTypes from 'prop-types'
import { createElement } from 'react'

import * as S from '../styles'

const CLAMP_LOWER = 1
const CLAMP_UPPER = 4
const MAX_PERCENT = 100

const Column = ({ children, columnsPerRow, ...props }) => (
  <S.Column columnsPerRow={columnsPerRow} columnWidth={MAX_PERCENT / clamp(columnsPerRow, CLAMP_LOWER, CLAMP_UPPER)}>
    {createElement(S.ColumnContent, { ...props }, <S.ColumnInner>{children}</S.ColumnInner>)}
  </S.Column>
)

Column.propTypes = {
  children: PropTypes.node.isRequired,
  columnsPerRow: PropTypes.number,
}

export default Column
